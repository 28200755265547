"use strict";

function trackPChange() {
  window.ga = window.ga || function () {
    (ga.q = ga.q || []).push(arguments);
  };

  ga.l = +new Date();
  ga('create', 'UA-178518682-1', 'auto');
  ga("set", "location", window.location.href);
  ga("send", "pageview");
}