"use strict";

(function () {
  Turbolinks.start();

  var ready = function ready() {
    console.log("🦄 TurboLinks Ready -> " + window.location.href + " width: " + $(window).width());
    var pageWidth = $(window).width(); // We reset the scroll to 0 at every page load

    $('html, body').scrollTop(0);

    if (pageWidth >= 481) {
      setTimeout(function () {
        setAnimationList();
      }, 100);
    }

    $('a').bkoScroll();
    $("body").bkoCookies({
      bkoBgColor: '#0f3e62'
    });
    $(".menu-mobile").bkoMenu();
    $(".contact-form").bkoContact('fca9f9581d7ebb4c0834ef4b0079bb9fc059d0e2', 'nancy.marche@drh-avenir.fr');
    lazywatcher();
    $('.mentions-legales-button').on('click', function () {
      $('.mentions-legales').toggleClass('openMentions');
    });
  };

  var cache = function cache() {
    console.log("🦄 TurboLinks CacheLoad");
    resetAnimationList();
  };

  document.addEventListener("turbolinks:before-cache", cache);
  document.addEventListener("turbolinks:load", ready);
  $(window).on('scroll', function () {
    var fromTop = $(window).scrollTop();

    if ($(window).width() >= 481) {
      setAnimationList();
    }

    $('body').toggleClass("down", fromTop > 150);
  });
  /**
   * Uncoment to unable google analitycs & update gulpdile.js
   */
  // gaInit( '{yourUA}' );
})(jQuery);