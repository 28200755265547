"use strict";

$.fn.bkoMenu = function (userParam) {
  var defaultParam = {
    'mainSelector': 'body',
    'menuSelector': this,
    'closeSelector': ".mobile-fermeture",
    'btnSelectorSub': ".sub-menu-btn",
    'burgerSelector': '.hamburger',
    'oppenSelector': '.openMenu',
    'oppenSubSelector': '.openSubMenu',
    'oppenBurgerSelector': '.is-active'
  };
  var param = $.extend(defaultParam, userParam);
  var $burger = $(param.burgerSelector);
  var $burgerAvtive = $(param.burgerSelector + param.oppenBurgerSelector);
  var $close = $(param.closeSelector);
  var $main = $(param.mainSelector);
  var isOppen = param.oppenSelector.substring(1);
  var isOppenSub = param.oppenSubSelector.substring(1);
  var isActive = param.oppenBurgerSelector.substring(1);
  var btnSelectorSub = param.btnSelectorSub.substring(1); // function

  var toggleBkoMenu = function toggleBkoMenu() {
    $main.toggleClass(isOppen);
    $burger.toggleClass(isActive);
  };

  if ($burger.isBound("bkoMenu") == false) {
    $burger.on("click", toggleBkoMenu);
    $close.on("click", toggleBkoMenu);
    $(".menu-mobile li a").on("click", toggleBkoMenu); // ajouter fleche sous-menu

    $(".menu-mobile li.menu-item-has-children").prepend("<div class='" + btnSelectorSub + "'><i class=\"fa fa-chevron-right\"></i></div>"); // le selecteur 'li.menu-item-has-children ' + param.btnSelectorSub
    // ne doit en aucun cas être stoqué dans une variable (probleme de porté du sélécteur $)

    $('li.menu-item-has-children ' + param.btnSelectorSub).on("click", function (e) {
      var toggleBtn = $(this).parent();

      if (toggleBtn.hasClass(isOppenSub)) {
        toggleBtn.removeClass(isOppenSub);
      } else {
        $(param.btnSelectorSub).removeClass(isOppenSub);
        toggleBtn.addClass(isOppenSub);
      }
    });
  } // reset


  $burgerAvtive.removeClass(isActive);
};